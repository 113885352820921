import { useEffect } from 'react';


function App() {
  useEffect(() => {
    const url = window.location.href;
    if(url.includes('advisors')){
      const advisorId = url.substring(url.lastIndexOf('/') + 1);
      window.location.href = `https://loanarea.btbisrael.co.il/gonogo/Welcome/Advisors/${advisorId}`;
    }
    else{
      window.location.href = "https://loanarea.btbisrael.co.il/gonogo/Welcome";      
    }    
  },);

  return (
    <div />         
  );
}

export default App;
